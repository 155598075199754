import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Spin } from 'antd'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Loading = ({ description, ...restProps }) => {
  return (
    <Container {...restProps}>
      <Spin size='large' tip={description} />
    </Container>
  )
}

Loading.propTypes = {
  description: PropTypes.string
}

export default Loading

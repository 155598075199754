import styled from 'styled-components'
import { Row, Col, Typography, List, Alert, Button, Descriptions, Divider } from 'antd'
import Result from './Result'
import Branding from '../../common/Branding'

import { BarChartOutlined, FilePdfOutlined } from '@ant-design/icons'
import { formatDate } from '../../../lib/date'

import { useTranslation } from 'react-i18next'

const { Text } = Typography

const ResultRow = styled(Row)`
  padding-top: 32px;
  width: 100%;
  @-moz-document url-prefix() {
    @media print {
      display: block;
    }
  }
`

const Title = styled(Typography.Title)`
  font-size: 32px;
  @media print {
    font-size: 24px;
  }
`

const LargeText = styled(Text)`
  font-size: 32px;
`

const ColHidePrint = styled(Col)`
  @media print {
    display: none;
  }
`

const ListItem = styled(List.Item)`
  display: block;
  @media print {
    page-break-before: always;
    break-before: always; /* Firefox */
  }
`

const PrintOnly = styled.div`
  @media print {
    display: block;
  }
  @media screen {
    display: ${props => props.debug ? 'block' : 'none'}};
  }
`

const Results = ({ title, polls, eventData, totalVoteWeight, loading, address, voteCount }) => {
  const { t } = useTranslation()

  return (
    <ResultRow gutter={[16, 16]} justify='center'>
      <Col span={24} align='middle'>
        <PrintOnly>
          <Title level={2}>{t('report.title')}</Title>
        </PrintOnly>
        <Title style={{ fontSize: 40 }} level={3}>{title}</Title>
        <PrintOnly>
          <Divider />
          <Descriptions column={1} labelStyle={{ width: 200 }}>
            <Descriptions.Item label={t('report.electionAddress')}>{address}</Descriptions.Item>
            <Descriptions.Item label={t('report.started')}>{formatDate(eventData?.started)}</Descriptions.Item>
            <Descriptions.Item label={t('report.ended')}>{formatDate(eventData?.ended)}</Descriptions.Item>
            <Descriptions.Item label={t('report.votes')}>{voteCount}</Descriptions.Item>
          </Descriptions>
        </PrintOnly>
      </Col>

      <Col xs={24} sm={22} md={20} xl={18} xxl={16} style={{ overflow: 'auto' }}>
        <List
          itemLayout='horizontal'
          split={false}
          loading={loading}
          dataSource={polls}
          renderItem={(poll, index) => {
            return (
              <ListItem>
                <PrintOnly>
                  <Branding />
                </PrintOnly>
                <Result number={index + 1} {...poll} totalVoteWeight={totalVoteWeight} eventData={eventData && eventData.polls[index]} voteCount={voteCount} />
              </ListItem>
            )
          }}
        />
      </Col>
      <ColHidePrint xs={24} sm={22} md={20} xl={18} xxl={16}>
        {!loading && <Alert showIcon icon={<BarChartOutlined style={{ fontSize: '30px' }} />} message={<LargeText>{t('election.poll.ended')}</LargeText>} type='error' />}
        <Col span={24} />
      </ColHidePrint>
      <ColHidePrint span={24} align='center'>
        {!loading && <Button icon={<FilePdfOutlined />} onClick={() => window.print()}>{t('election.report.generate')}</Button>}
        <Col span={24} />
      </ColHidePrint>
    </ResultRow>
  )
}

export default Results

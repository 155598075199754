import { useState, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Typography, Card, List, Row, Col, Progress, Avatar, Descriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import Icon from '@ant-design/icons'
import DonutChart from './DonutChart'
import { ReactComponent as WeightSvg } from '../Weight.svg'
import calculatePercentage from '../../../../lib/calculatePercentage'
import { formatDate } from '../../../../lib/date'

const { Title, Text } = Typography

const PRINT_TEXT_SIZE = 16
const LargeText = styled(Text)`
  font-size: 32px;
  @media print {
    font-size: ${PRINT_TEXT_SIZE}px;
  }
`

const DescriptionText = styled(Text)`
  font-size: 32px;
  text-align: left;
  @media print {
    font-size: ${PRINT_TEXT_SIZE}px;
  }
`

const PrintableRow = styled(Row)`
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
`

const PrintOnly = styled.div`
  @media print {
    display: block;
  }
  @media screen {
    display: ${props => props.debug ? 'block' : 'none'}};
  }
`

const Image = styled(Avatar)`
  margin-right: 8px;
`

const ResultCard = styled(Card)`
  .ant-card-head {
    background: transparent;
    border-width: 0px;
  }
`

const WeightIcon = (props) => <Icon style={{ paddingLeft: 4 }} component={WeightSvg} {...props} />

const Result = ({ title, number, answers, allowAbstention, abstentionCount, abstentionWeight, voterCount, voteCount, minVotes, maxVotes, totalVoteWeight, metadata, eventData }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [currentVoteCount, setCurrentVoteCount] = useState(0)
  useEffect(() => {
    if (!answers) {
      return null
    }
    let votes = 0
    answers.forEach(answer => {
      votes += Number(answer.votes)
    })
    setCurrentVoteCount(votes + abstentionWeight)
  }, [answers, abstentionWeight])
  const abstentionPercentage = currentVoteCount ? calculatePercentage(abstentionWeight, currentVoteCount) : 0
  const areVoteCountsEqual = minVotes === maxVotes

  const data = answers
    ? (
        answers.map(answer => {
          return {
            type: answer.title,
            value: answer.votes,
            image: answer.image
          }
        })
      )
    : []

  if (allowAbstention) {
    data.push({
      type: t('election.poll.abstain'),
      value: abstentionWeight
    })
  }

  const showPieChart = metadata?.resultDisplayType === 'pie'

  return (
    <ResultCard
      hoverable={false}
      bordered={false}
      title={<Title align='left' level={2} style={{ whiteSpace: 'pre-line' }}>{number}. {title}</Title>}
    >
      <PrintOnly>
        <Descriptions column={1}>
          <Descriptions.Item label={t('report.opened')}>{formatDate(eventData?.opened)}</Descriptions.Item>
          <Descriptions.Item label={t('report.lastVote')}>{formatDate(eventData?.lastVoteReceived)}</Descriptions.Item>
          <Descriptions.Item label={t('report.closed')}>{formatDate(eventData?.closed)}</Descriptions.Item>
          <Descriptions.Item label={t('report.votesReceived')}>{`${voterCount} / ${voteCount}`}</Descriptions.Item>
        </Descriptions>
      </PrintOnly>
      {showPieChart && <DonutChart data={data} />}
      <List
        split
        itemLayout='horizontal'
        dataSource={answers}
        grid={{ gutter: 16, column: 1 }}
        renderItem={(answer, index) => {
          const percentage = calculatePercentage(answer.votes, maxVotes > 1 ? totalVoteWeight : currentVoteCount)
          return (
            <List.Item>
              <PrintableRow justify='start' align='middle' gutter={8}>
                {showPieChart && (
                  <Col flex='40px'>
                    <Image shape='circle' size={20} style={{ backgroundColor: theme.chartColors[index % theme.chartColors.length] }} />
                  </Col>
                )}
                {answer.image && (
                  <Col flex='40px'>
                    <Image shape='circle' size={40} src={answer.image} />
                  </Col>
                )}
                <Col span={20} style={{ display: 'flex' }}>
                  <LargeText align='left'>
                    {answer.title}
                    <Text align='left' type='secondary'> ({answer.votes}{currentVoteCount > voterCount ? <WeightIcon /> : null})</Text>
                  </LargeText>
                </Col>

                {!showPieChart && (
                  <Col xs={20} lg={21} align='left'>
                    <Progress
                      status='normal'
                      percent={percentage}
                      showInfo={false}
                      strokeColor={theme.primaryColor}
                    />
                  </Col>
                )}
                {!showPieChart && (
                  <Col xs={4} lg={3} align='right'><LargeText>{percentage}%</LargeText></Col>
                )}
              </PrintableRow>
            </List.Item>
          )
        }}
      />
      {allowAbstention && (
        <PrintableRow justify='start' align='middle' gutter={8}>
          {showPieChart && (
            <Col flex='40px'>
              <Image shape='circle' size={20} style={{ backgroundColor: theme.chartColors[answers.length % theme.chartColors.length] }} />
            </Col>
          )}
          <Col span={22} style={{ display: 'flex' }}>
            <LargeText align='left'>
              {t('election.poll.abstain')}
              <Text align='left' type='secondary'> ({abstentionCount}{currentVoteCount > voterCount ? <WeightIcon /> : null})</Text>
            </LargeText>
          </Col>
          {!showPieChart && (
            <>
              <Col xs={20} lg={21} align='left'>
                <Progress status='normal' percent={abstentionPercentage} showInfo={false} strokeColor={theme.primaryColor} />
              </Col>
              <Col xs={4} lg={3} align='right'><LargeText>{abstentionPercentage}%</LargeText></Col>
            </>
          )}
        </PrintableRow>
      )}
      <PrintableRow>
        <DescriptionText align='left' italic type='secondary'>{areVoteCountsEqual ? t('election.poll.equalVoteCountDescription', { count: minVotes || maxVotes || 1 }) : t('election.poll.voteCountDescription', { min: minVotes, max: maxVotes })}</DescriptionText>
      </PrintableRow>
    </ResultCard>
  )
}

export default Result

import styled from 'styled-components'
import { ReactComponent as Logo } from './voteos.svg'

const LogoContainer = styled.div`
  padding-top: 40px;
  padding-left: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media print {
    padding: 0;
    padding-bottom: 20px;
  }
`

const StyledLogo = styled(Logo)`
  width: 160px;
  margin-right: 10px;
`

export default function HeaderLogo () {
  return (
    <LogoContainer>
      <StyledLogo />
    </LogoContainer>
  )
}

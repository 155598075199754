import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import theme from '../../../../theme'
import Logo from './Logo'

const styles = StyleSheet.create({
  container: {
    marginBottom: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  slogan: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    color: theme['primary-color'],
    textAlign: 'right'
  },
  image: {
    height: 40,
    marginLeft: -10
  }
})

const Header = () => {
  const { t } = useTranslation()

  return (
    <View style={styles.container} fixed>
      <Logo />
      <Text style={styles.slogan}>{t('report.header.slogan')}</Text>
    </View>
  )
}

export default Header

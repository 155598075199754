import { View, Text, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row'
  },
  text: {
    fontSize: 12
  },
  value: {
    position: 'absolute',
    left: 150,
    right: 0
  }
})

export const DescriptionText = ({ label, value }) => <Text style={styles.text}>{label}: {value}</Text>

const Description = ({ label, value }) => {
  if (value instanceof Date) {
    value = value.toISOString().substring(0, 16).replace('T', ' ')
  }
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{label}:</Text>
      <Text style={[styles.text, styles.value]}>{value}</Text>
    </View>
  )
}
export default Description

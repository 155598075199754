import styled from 'styled-components'
import { Row, Col, Typography, List } from 'antd'
import Answer from './Answer'
import { useTranslation } from 'react-i18next'
const { Title } = Typography

const DescriptionText = styled(Typography.Text)`
  font-size: 32px;
  text-align: left;
`

const Question = ({ title, number, answers, allowAbstention, allowMultiple, metadata, minVotes, maxVotes }) => {
  const { t } = useTranslation()

  const areVoteCountsEqual = minVotes === maxVotes
  return (
    <Row gutter={[16, 16]} justify='start'>
      <Col span={24}>
        <Title align='left' style={{ fontSize: 40 }} level={2}>{number}. {title}</Title>
      </Col>

      <Col span={24}>
        <List
          itemLayout='horizontal'
          split={false}
          dataSource={answers}
          renderItem={(answer, index) => {
            return (
              <Answer {...answer} allowMultiple={allowMultiple} />
            )
          }}
        />
        {allowAbstention && <Answer title={t('election.poll.abstain')} />}
      </Col>
      <DescriptionText italic type='secondary'>{areVoteCountsEqual ? t('election.poll.equalVoteCountDescription', { count: minVotes }) : t('election.poll.voteCountDescription', { min: minVotes, max: maxVotes })}</DescriptionText>
    </Row>
  )
}

export default Question

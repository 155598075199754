import React from 'react'
import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import Header from './Header'
import Footer from './Footer'
import ProgressBar from './ProgressBar'
import Separator from './Separator'
import Spacer from './Spacer'
import Description, { DescriptionText } from './Description'
import Attachments from './Attachments'
const calculatePercentage = (value, total) => {
  if (!total) {
    return 0
  }
  return Math.round((value / total * 100) * 100) / 100
}
const styles = StyleSheet.create({
  page: {
    padding: 40,
    paddingBottom: 60
  },
  section: {
    marginTop: 30,
    marginBottom: 10
  },
  title: {
    fontSize: 24,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 40
  },
  subtitle: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 18
  },
  answerImage: {
    width: 20,
    height: 20,
    marginRight: 5
  },
  questionHeadline: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold'
  },
  question: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold'
  },
  questionDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  answer: {
    marginTop: 10,
    fontSize: 12
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})

const PDFDocument = ({ data }) => {
  const { t } = useTranslation()
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Header />
        <Text style={styles.title}>{t('report.title')}</Text>
        <Text style={styles.subtitle}>{data.title || ''}</Text>
        <Separator />
        <Description label={t('report.electionAddress')} value={data.address} />
        <Description label={t('report.started')} value={data.started} />
        <Description label={t('report.ended')} value={data.ended} />
        <Description label={t('report.votes')} value={data.voteCount} />
        <Attachments items={data.attachments} />
        <Spacer height={20} />
        {data && data.polls.length && data.polls.map((poll, index) => {
          if (!poll || !poll.answers || !poll.answers.length) {
            return null
          }
          let totalVotes = !isNaN(poll.abstentionWeight) ? poll.abstentionWeight : 0
          poll.answers.forEach(answer => {
            totalVotes += Number(answer.votes)
          })
          return (
            <View key={poll.id} style={styles.section} wrap={false}>
              <Text style={styles.questionHeadline}>{t('report.question').toUpperCase()} {index + 1}</Text>
              <Separator />
              <View style={styles.questionDetails}>
                <View style={{ flex: 1 }}>
                  <Description label={t('report.opened')} value={poll.opened} />
                  <Description label={t('report.lastVote')} value={poll.lastVoteReceived} />
                  <Description label={t('report.closed')} value={poll.closed} />
                </View>
                <DescriptionText label={t('report.votesReceived')} value={`${poll.voterCount} / ${data.voteCount}`} />
              </View>
              <Text style={styles.question}>{poll.title || ''}</Text>
              <Attachments items={poll.metadata.attachments} />
              {poll.answers.map((answer) => (
                <View key={answer.id} style={styles.answer} wrap={false}>
                  <View style={styles.row}>
                    {answer.image && <Image style={styles.answerImage} src={answer.image} />}
                    <Text>{answer.title} ({answer.votes})</Text>
                  </View>
                  <ProgressBar percentage={calculatePercentage(answer.votes, poll.maxVotes > 1 ? data.totalVoteWeight : totalVotes)} />
                </View>
              ))}
              {poll.allowAbstention && (
                <View style={styles.answer} wrap={false}>
                  <Text>{t('report.abstentions')} ({poll.abstentionWeight})</Text>
                  <ProgressBar percentage={calculatePercentage(poll.abstentionWeight, totalVotes)} />
                </View>
              )}
            </View>
          )
        }
        )}

        <Footer />
      </Page>
    </Document>
  )
}

export default PDFDocument

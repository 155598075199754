import { View, Text, Link, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  text: {
    fontSize: 12
  },
  link: {
    textAlign: 'right',
    paddingRight: 40,
    fontSize: 12,
    color: 'black',
    textDecoration: 'none'
  },
  spacer: {
    width: 120
  }
})

const Footer = () => (
  <View style={styles.container} fixed>
    <View style={styles.spacer} />
    <Text
      style={styles.text}
      render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )}
    />
    <Link style={styles.link} src='https://voteos.com'>www.voteos.com</Link>
  </View>
)

export default Footer

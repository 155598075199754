import { Result } from 'antd'

export default function ElementNotFound ({ text, title, homeLink = '/' }) {
  return (
    <Result
      status='404'
      title={title}
      subTitle={text}
    />
  )
}

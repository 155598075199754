import { Route, Switch } from 'wouter'
import { ElectionProvider, OrganizationProvider } from '@hpm/voteos-hooks'

import Present from './Present'
import Report from './Report'
import NotFound from './NotFound'
import Legal from './Legal'

export default function Pages () {
  return (
    <OrganizationProvider>
      <ElectionProvider>
        <Switch>
          <Route path='/legal' component={Legal} />
          <Route path='/:address' component={Present} />
          <Route path='/:address/report' component={Report} />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </ElectionProvider>
    </OrganizationProvider>
  )
}

import { View, Text, Link, StyleSheet } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import theme from '../../../../theme'

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    width: '100%'
  },
  text: {
    fontSize: 12
  },
  link: {
    fontSize: 12,
    paddingLeft: 5,
    color: theme['primary-color'],
    textDecoration: 'none'
  }
})

const Attachments = ({ items = [], style, ...restProps }) => {
  const { t } = useTranslation()

  if (!items.length) return null

  return (
    <View style={[styles.container, style]} {...restProps}>
      <Text style={styles.text}>{t('report.attachments')}:</Text>
      {items.map(({ name, link, uri }) => (
        <Text key={uri}>- <Link style={styles.link} src={link}>{name}</Link></Text>
      ))}
    </View>
  )
}
Attachments.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ link: PropTypes.string, name: PropTypes.string }))
}

export default Attachments

import { useState, useEffect, useCallback } from 'react'
import styled, { useTheme } from 'styled-components'
import { Typography, Row, Col, Spin, Card, Alert } from 'antd'
import { SyncOutlined, BarChartOutlined, LockOutlined } from '@ant-design/icons'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import Loading from '../../common/Loading'
import NotFound from '../../common/NotFound'
import Logo from '../../common/Logo'
import Branding from '../../common/Branding'
import Question from './Question'
import Result from './Result'
import Results from './Results'
import getTimestampDate from '../../../lib/date'
import { useElection, useOrganization } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'
const { Title, Text } = Typography

const LargeText = styled(Text)`
  font-size: 32px;
`

const BlockContainer = styled.div`
  display: block;
  @media print {
    padding-top: 20px;
  }
`

const FullScreenContainer = styled(FullScreen)`
  width: 100%;
  min-height: 100vh;
  overflow: scroll;
  background-color: white;
`

const TitleRow = styled(Row)`
  height: 60vh;
  padding-top: 32px;
`

const QuestionCard = styled(Card)`
  width: 100%;
  height: 100vh;
  @page {
    -webkit-print-color-adjust: exact;
  }
  @media print {
    -webkit-print-color-adjust: exact;
  }
`

const LegalInfoLink = styled.a`
  @media print {
    display:none;
  }
  color: #9c9c9c;
  text-decoration: underline;
  &:active, &:hover, &:focus, &:visited {
    color: #9c9c9c;
    text-decoration: underline;
  }
`

const alertTypes = {
  open: 'success',
  closed: 'warning',
  ended: 'info'
}

export default function Present ({ params }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const handle = useFullScreenHandle()
  const { election, polls, events, loading, loadEvents, subscribeToEvents, setAddress, listVotes } = useElection()
  const { organization = {}, loading: loadingOrganization, setAddress: setOrganizationAddress } = useOrganization()
  const [totalVoteWeight, setTotalVoteWeight] = useState()
  const [reportData, setReportData] = useState()

  const loadVoteWeight = useCallback(async () => {
    if (!election || !election.voteCount) {
      return
    }
    const votes = await listVotes(0, election.voteCount)
    const totalWeight = votes.reduce((previous, current) => {
      return previous + (current.weight || 1)
    }, 0)
    setTotalVoteWeight(totalWeight)
  }, [listVotes, election])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const printParam = params.get('print')
    if (printParam && !loading && !loadingOrganization && election.state === 'ended' && totalVoteWeight && reportData) {
      setTimeout(() => {
        window.print()
      }, 1000)
    }
    if (election && election.title) {
      document.title = `${t('appname')} - ${election.title}`
    }
  }, [loading, loadingOrganization, election, totalVoteWeight, reportData, t])

  useEffect(() => {
    setAddress(params.address)
  }, [params.address, setAddress])

  useEffect(() => {
    loadVoteWeight()
    if (election && election.organizationAddress) {
      setOrganizationAddress(election.organizationAddress)
    }
    if (election && !totalVoteWeight) {
      loadVoteWeight()
    }
    if (election && election.state === 'ended' && polls && events && events.length && !loading) {
      const parsedEventData = events.reduce((eventData, currentEvent) => {
        const timestampDate = getTimestampDate(currentEvent.timestamp)
        const pollIndex = Number(currentEvent.values.pollId)
        switch (currentEvent.name) {
          case 'UpdateInformation': {
            if (['started', 'ended'].includes(currentEvent.values.value)) {
              eventData[currentEvent.values.value] = timestampDate
            }
            break
          }
          case 'Voted': {
            if (!eventData.polls[pollIndex]) {
              eventData.polls[pollIndex] = {}
            }
            if (eventData.polls[pollIndex] || !eventData.polls[pollIndex].lastVoteReceived || timestampDate > eventData.polls[pollIndex].lastVoteReceived) {
              eventData.polls[pollIndex].lastVoteReceived = timestampDate
            }
            break
          }
          case 'UpdateActivePoll': {
            const poll = eventData.polls[pollIndex] ? eventData.polls[pollIndex] : {}
            if (currentEvent.values.acceptingVotes) {
              if (!poll.opened || (poll.opened && poll.opened > timestampDate)) {
                eventData.polls[pollIndex] = { ...eventData.polls[pollIndex], opened: timestampDate }
              }
            }
            if (!currentEvent.values.acceptingVotes) {
              if (!poll.closed || (poll.closed && poll.closed < timestampDate)) {
                eventData.polls[pollIndex] = { ...eventData.polls[pollIndex], closed: timestampDate }
              }
            }
            break
          }
          default:
        }
        return eventData
      }, { polls: [] })
      setReportData({ ...parsedEventData, totalVoteWeight })
    }
  }, [loading, events, election, polls, totalVoteWeight, setOrganizationAddress, loadVoteWeight])

  useEffect(() => {
    return subscribeToEvents()
  }, [subscribeToEvents])

  useEffect(() => {
    loadEvents()
  }, [loadEvents])

  const handleKeypress = useCallback((event) => {
    if (event.key === 'f') {
      if (handle.active) {
        handle.exit()
      } else {
        handle.enter()
      }
    }
  }, [handle])

  useEffect(() => {
    document.addEventListener('keydown', handleKeypress, false)
    return () => {
      document.removeEventListener('keydown', handleKeypress, false)
    }
  }, [handleKeypress])

  if ((loading || loadingOrganization) && !election && !polls) {
    return <Loading description={t('election.loadingHint')} />
  }

  if ((!loading && !election) || (election.state === 'created')) {
    return <NotFound title={t('election.notFound')} />
  }

  const getIconForPollState = ({ active, isAcceptingVotes }, pollState) => {
    if (active && isAcceptingVotes) return SyncOutlined

    const currentlyActivePoll = polls.find(p => !!p.active)
    if (currentlyActivePoll && !currentlyActivePoll.isAcceptingVotes && pollState === 'ended') {
      return BarChartOutlined
    }
    return LockOutlined
  }

  const { title, state, metadata, activePollId } = election
  const hasLogo = metadata && metadata.logo
  const titleCard = (
    <TitleRow align='middle'>
      <Col span={24} align='middle'>
        <Spin spinning={!organization || !organization.title}>
          <Title level={2} style={{ fontSize: 60 }}>{organization.title}</Title>
        </Spin>
      </Col>
      {hasLogo && (
        <Col span={24} align='middle'>
          <Logo src={metadata.logo} />
        </Col>
      )}
      <Col span={24} align='middle'>
        <Title align='center' style={{ fontSize: 80 }}>{title}</Title>
      </Col>
    </TitleRow>
  )

  let question
  if (activePollId && polls && polls.length) {
    const currentIndex = polls.findIndex(poll => poll.id === activePollId)
    const currentPoll = polls[currentIndex]
    const hasReceivedVotes = currentPoll.answers.some((answer) => answer.votes > 0)
    let pollState = 'closed'
    if (!currentPoll.isAcceptingVotes && (hasReceivedVotes || currentPoll.abstentionCount)) {
      pollState = 'ended'
    } else if (currentPoll.isAcceptingVotes) {
      pollState = 'open'
    }
    const Icon = getIconForPollState(currentPoll, pollState)
    question = (
      <QuestionCard bordered={false} bodyStyle={{ padding: '16px', height: '100%' }} type='inner'>
        <Row justify='center' align='middle' gutter={[0, 32]} style={{ height: '100%' }}>
          <Col xs={24} sm={22} md={20} lg={18} xl={16} align='middle'>
            {currentPoll.isAcceptingVotes || (!currentPoll.isAcceptingVotes && !hasReceivedVotes && !currentPoll.abstentionCount)
              ? (
                <Question number={currentIndex + 1} {...currentPoll} />
                )
              : (
                <Result number={currentIndex + 1} {...currentPoll} totalVoteWeight={totalVoteWeight} />
                )}
          </Col>
          <Col xs={24} sm={22} md={20} lg={18} xl={16}>
            <Alert
              showIcon
              style={pollState === 'ended' ? { backgroundColor: '#E6F7FF', borderColor: theme.primaryColor } : null}
              icon={<Icon spin={pollState === 'open'} style={{ fontSize: '30px', color: pollState === 'ended' ? theme.primaryColor : null }} />}
              message={<LargeText>{t('election.poll.' + pollState)}</LargeText>}
              type={alertTypes[pollState]}
            />
          </Col>
        </Row>
      </QuestionCard>
    )
  }

  return (
    <BlockContainer>
      <Branding />
      <FullScreenContainer handle={handle}>
        {state === 'opened' && titleCard}
        {state === 'started' && question}
        {state === 'ended' && (
          <Results
            {...election}
            title={title}
            polls={polls}
            address={params.address}
            totalVoteWeight={totalVoteWeight}
            loading={loading || loadingOrganization || !reportData}
            eventData={reportData}
          />
        )}
      </FullScreenContainer>
      <Row justify='center' align='middle'>
        <LegalInfoLink href='/legal' target='_blank' rel='noreferrer'>{t('legal.title')}</LegalInfoLink>
      </Row>
    </BlockContainer>
  )
}

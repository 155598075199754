import { View, Text, StyleSheet } from '@react-pdf/renderer'

const DEFAULT_HEIGHT = 5

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10
  },
  text: {
    width: 40,
    fontSize: 10,
    textAlign: 'right'
  }
})
const backgroundStyle = ({ height = DEFAULT_HEIGHT, backgroundColor = '#f5f5f5' }) => ({
  width: '100%',
  left: 0,
  height,
  borderRadius: height / 2,
  marginRight: 10,
  backgroundColor
})

const barStyle = ({ percentage = 0, height = DEFAULT_HEIGHT, color = '#1890ff' }) => ({
  width: percentage + '%',
  height,
  borderRadius: height / 2,
  backgroundColor: color
})

const ProgressBar = ({ percentage, height }) => {
  return (
    <View style={styles.container}>
      <View style={backgroundStyle({ height })}>
        <View style={barStyle({ percentage, height })} />
      </View>
      <Text style={styles.text}>{percentage}%</Text>
    </View>
  )
}

export default ProgressBar

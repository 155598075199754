import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Loading from '../../common/Loading'
import NotFound from '../../common/NotFound'
import { useElection } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'
import { PDFViewer } from '@react-pdf/renderer'
import Document from './Document'

const PdfViewer = styled(PDFViewer)`
  width: 100vw;
  height: 100vh;
  border: 0;
`

export default function Report ({ params }) {
  const { t } = useTranslation()

  const { election, polls, events, loading, loadEvents, setAddress, listVotes } = useElection()
  const [reportData, setReportData] = useState()
  const [totalVoteWeight, setTotalVoteWeight] = useState(0)
  const loadVoteWeight = useCallback(async () => {
    const votes = await listVotes()
    const totalWeight = votes.reduce((previous, current) => {
      return previous + (current.weight || 1)
    }, 0)
    setTotalVoteWeight(totalWeight)
  }, [listVotes])

  useEffect(() => {
    setAddress(params.address)
  }, [params.address, setAddress])

  useEffect(() => {
    loadEvents()
  }, [loadEvents])

  const getTimestampDate = (timestamp) => {
    return new Date(timestamp * 1000)
  }
  useEffect(() => {
    if (election && !totalVoteWeight) {
      loadVoteWeight()
    }
    if (election && polls && events && events.length && !loading) {
      const parsedEventData = events.reduce((eventData, currentEvent) => {
        const timestampDate = getTimestampDate(currentEvent.timestamp)
        switch (currentEvent.name) {
          case 'UpdateInformation': {
            if (['started', 'ended'].includes(currentEvent.values.value)) {
              eventData[currentEvent.values.value] = timestampDate
            }
            break
          }
          case 'Voted': {
            if (!eventData.polls[currentEvent.values.pollId]) {
              eventData.polls[currentEvent.values.pollId] = {}
            }
            if (!eventData.polls[currentEvent.values.pollId] || !eventData.polls[currentEvent.values.pollId].lastVoteReceived || timestampDate > eventData.polls[currentEvent.values.pollId].lastVoteReceived) {
              eventData.polls[currentEvent.values.pollId].lastVoteReceived = timestampDate
            }
            break
          }
          case 'UpdateActivePoll': {
            const poll = eventData.polls[currentEvent.values.pollId] ? eventData.polls[currentEvent.values.pollId] : {}
            if (currentEvent.values.acceptingVotes) {
              if (!poll.opened || (poll.opened && poll.opened > timestampDate)) {
                eventData.polls[currentEvent.values.pollId] = { ...eventData.polls[currentEvent.values.pollId], opened: timestampDate }
              }
            }
            if (!currentEvent.values.acceptingVotes) {
              if (!poll.closed || (poll.closed && poll.closed < timestampDate)) {
                eventData.polls[currentEvent.values.pollId] = { ...eventData.polls[currentEvent.values.pollId], closed: timestampDate }
              }
            }
            break
          }
          default:
        }
        return eventData
      }, { ...election, polls })
      setReportData({ ...parsedEventData, totalVoteWeight })
    }
  }, [events, election, polls, loading, loadVoteWeight, totalVoteWeight])

  if (loading || !reportData) {
    return <Loading description={t('election.report.generating')} />
  }

  if ((!loading && !election) || (election.state !== 'ended')) {
    return <NotFound title={t('election.notFound')} />
  }

  return (
    <PdfViewer>
      <Document data={reportData} />
    </PdfViewer>
  )
}

export const getTimestampDate = (timestamp) => {
  return new Date(timestamp * 1000)
}

export const formatDate = (date) => {
  if (!date) return ''
  const timezoneOffset = date.getTimezoneOffset() * 60000
  const localDate = new Date(date.getTime() - timezoneOffset)
  const formattedDate = localDate.toISOString().substring(0, 19).replace('T', ' ')
  // const formattedDate = date.toISOString().substring(0, 16).replace('T', ' ')
  return formattedDate
}

export default getTimestampDate

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { List, Typography, Avatar, Row, Col } from 'antd'
import { ReactComponent as Checkbox } from './Checkbox.svg'
import { ReactComponent as Radio } from './Radio.svg'

const LargeText = styled(Typography.Text)`
  font-size: 32px;
  display: block;
  margin-left: 8px;
`

const CheckBox = styled(Checkbox)`
  width: 24px;
  margin-right: 8px;
`

const RadioBox = styled(Radio)`
  width: 24px;
  margin-right: 8px;
`

const Answer = ({ title = '', image, allowMultiple }) => {
  return (
    <List.Item>
      <Row align='middle' gutter={[8]} wrap={false}>
        {allowMultiple ? <CheckBox /> : <RadioBox />}
        {image && (
          <Col>
            <Avatar shape='circle' size={50} src={image} />
          </Col>
        )}
        <Col flex='auto'>
          <LargeText align='left'>
            {title}
          </LargeText>
        </Col>
      </Row>
    </List.Item>
  )
}

Answer.propTypes = {
  text: PropTypes.string,
  editing: PropTypes.bool,
  onDelete: PropTypes.func,
  onChangeText: PropTypes.func,
  onPressEnter: PropTypes.func
}

export default Answer
